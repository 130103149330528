import React from "react";
import { alpha, Box, styled, Typography, useTheme, InputAdornment } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getProductList } from "reduxs/actions";
import { SimpleTable, StyledAccordion, StyledCheckbox } from "ui";
import { InputField, SelectField } from "ui/form/field";

const ProductTable = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  borderSpacing: "0",

  "& tr:nth-of-type(even)": {
    "& td": {
      backgroundColor: alpha(theme.palette.grey.cadet, 0.16),
    },
  },
}));

const TableItem = styled(Box)(({ theme }) => ({
  textAlign: "left",
}));

const HomePorductSelection = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();

  const { productList } = useSelector((state) => state.product);

  const isAllchecked = (action, checks) => {
    const checked = { full: false, partial: false };
    if (action.length && action.every((v) => checks.includes(v))) {
      checked.full = true;
    } else if (action.some((v) => checks.includes(v))) {
      checked.partial = true;
    } else {
      checked.full = false;
      checked.partial = false;
    }
    return checked;
  };

  const handleSelectProduct = React.useCallback(
    (productItem) => (event) => {
      const { products } = values;
      const index = products.indexOf(productItem);
      const arr =
        index === -1 ? [...products, productItem] : [...products.slice(0, index), ...products.slice(index + 1)];

      setFieldValue("products", arr);
    },
    [values]
  );

  const createRows = (product, title) => {
    return (
      <React.Fragment key={product.id}>
        {product?.childrenProduct?.length > 0 &&
        product?.childrenProduct?.filter((v) => v?.childrenProduct?.length > 0)?.length > 0 ? (
          product.childrenProduct.map((subChild) => {
            const newTitle = title ? `${title} - ${subChild.name}` : subChild.name;
            return createRows(subChild, newTitle);
          })
        ) : (
          <tr>
            <td colSpan="4" className="!p-0 ">
              <ProductTable>
                <tbody>
                  {/* Print Product Items */}

                  {/* Product sibling parent row */}
                  <tr>
                    <TableItem component="td" width="40px" sx={{ padding: "0 !important", textAlign: "center" }}>
                      <StyledCheckbox
                        sx={{ mx: 0 }}
                        name={product.name}
                        checkboxProps={{
                          checked: isAllchecked(product?.childrenProduct, values.products).full,
                          indeterminate: isAllchecked(product?.childrenProduct, values.products).partial,
                          onChange: (event) => {
                            const products = [...values.products];
                            const productItemList = product?.childrenProduct?.map((productItem) => {
                              return productItem;
                            });
                            productItemList.forEach((productItem) => {
                              if (event.target.checked) {
                                if (!products.includes(productItem)) {
                                  products.push(productItem);
                                }
                              } else {
                                if (products.includes(productItem)) {
                                  const index = products.indexOf(productItem);
                                  products.splice(index, 1);
                                }
                              }
                            });
                            setFieldValue("products", products);
                          },
                        }}
                      />
                    </TableItem>
                    <TableItem
                      component="td"
                      colSpan="3"
                      sx={{ pl: "5px !important", color: theme.palette.info.main }}
                      className="font-semibold"
                    >
                      <Typography sx={{ my: 1.875 }}>{title}</Typography>
                    </TableItem>
                  </tr>

                  {/* List of all products of last type of child */}
                  {product?.childrenProduct.length > 0 ? (
                    product?.childrenProduct.map((lastChild) => {
                      return (
                        <tr key={lastChild.id}>
                          <TableItem component="td" width="40px" sx={{ padding: "0 !important", textAlign: "center" }}>
                            <StyledCheckbox
                              sx={{ mx: 0 }}
                              name={`${lastChild}`}
                              checkboxProps={{
                                checked: values?.products?.includes(lastChild),
                                onChange: handleSelectProduct(lastChild),
                              }}
                            />
                          </TableItem>
                          <TableItem component="td" width="189.63px" sx={{ pl: "5px !important" }}>
                            {lastChild.name}
                          </TableItem>
                          <TableItem component="td" width="172.19px">
                            <SelectField
                              disabled={!values?.products?.includes(lastChild)}
                              name={`type-${lastChild.id}`}
                              dense={true}
                              placeholder="Select type"
                              options={[]}
                            />
                          </TableItem>
                          <TableItem component="td" width="172.19px">
                            <InputField
                              disabled={!values?.products?.includes(lastChild)}
                              name={`price-${lastChild.id}`}
                              dense={true}
                              type="number"
                              placeholder="Price"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                          </TableItem>
                        </tr>
                      );
                    })
                  ) : (
                    // Empty State
                    <tr>
                      <TableItem component="td" colSpan="4">
                        <Typography sx={{ color: "#909090", textAlign: "center" }}>
                          No items in this category
                        </Typography>
                      </TableItem>
                    </tr>
                  )}
                </tbody>
              </ProductTable>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    dispatch(getProductList());
  }, []);

  return (
    <Box component="section" sx={{ mt: 5 }}>
      <Typography component="h3" sx={{ mb: 2, fontSize: 18, fontWeight: 600, lineHeight: "22px" }}>
        Select products for house
      </Typography>

      <Box sx={{ columnCount: { xl: 2 }, columnGap: 0, mx: -1 }}>
        {productList?.length &&
          productList.map((parent) => {
            return (
              <Box key={parent.id} sx={{ display: "inline-block", p: 1 }}>
                <StyledAccordion
                  label={
                    <Box
                      component="h5"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        m: 0,
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: "22px",
                      }}
                    >
                      <StyledCheckbox
                        name={parent.name}
                        checkboxProps={{
                          onClick: (e) => e.stopPropagation(),
                        }}
                      />
                      <Typography component="span" sx={{ color: theme.palette.info.main }} className="font-semibold">
                        {parent.name}
                      </Typography>
                    </Box>
                  }
                  labelprops={{
                    expandIcon: <ExpandMoreIcon />,
                  }}
                  wrapperprops={{
                    defaultExpanded: true,
                    sx: {
                      border: `1px solid ${alpha(theme.palette.grey.cadet, 0.1)}`,
                      boxShadow: "0px 5px 10px -4px rgba(145, 158, 171, 0.12)",
                    },
                  }}
                >
                  <SimpleTable
                    tableProps={{
                      sx: { "& td, th": { padding: "5.5px 12px" } },
                    }}
                  >
                    <thead>
                      <tr>
                        <TableItem component="th" width="40px" sx={{ padding: "0 !important" }}></TableItem>
                        <TableItem component="th" width="189.63px" sx={{ pl: "5px !important" }}>
                          Product
                        </TableItem>
                        <TableItem component="th" width="172.19px">
                          Type
                        </TableItem>
                        <TableItem component="th" width="172.19px">
                          Price
                        </TableItem>
                      </tr>
                    </thead>

                    <tbody>
                      {parent.childrenProduct.map((child) => {
                        return createRows(child, child.name);
                      })}
                    </tbody>
                  </SimpleTable>
                </StyledAccordion>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default HomePorductSelection;
