import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DataUsageRoundedIcon from "@mui/icons-material/DataUsageRounded";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "reduxs/actions";
import { checkPermission } from "helpers";
import { Action, BreadcrumbContainer, Empty, StyledCard, StyledSpinner } from "ui";
import graphics from "assets/images/home-placeholder.jpg";
import UserHomeView from "./UserHomeView";

const MyHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData, loading } = useSelector((state) => state.user);
  const { permissionList } = useSelector((state) => state.shared);

  const handleViewClick = (e, id) => {
    e.preventDefault();
    navigate(`/configuration/home/view/${id}`);
  };

  const handleCustomizeClick = (e, userId, userHomeId) => {
    e.preventDefault();
    navigate(`/my-home/customize/${userHomeId}`);
  };

  const handleSelectionClick = (e, userId, userHomeId) => {
    e.preventDefault();
    navigate(`/my-home/summary/${userHomeId}`);
  };

  const handleDocumentClick = (e, userId, userHomeId) => {
    e.preventDefault();
    navigate(`/my-home/document/${userHomeId}`);
  };

  const handleBuildingProgressClick = (e, userId, userHomeId) => {
    e.preventDefault();
    navigate(`/my-home/building-progress/${userHomeId}`);
  };

  React.useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("currentUser"))?.id;
    if (userId) dispatch(getUser(userId));
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title={"My homes"}
          paths={[
            {
              title: "My homes",
            },
          ]}
        />
      </Box>

      {userData?.userHomes?.length > 1 && (
        <>
          <Typography component="h3" fontSize={18} fontWeight={600} mb={2.5}>
            List of available homes ready for customization.
          </Typography>

          <Grid container spacing={3}>
            {userData?.userHomes?.map((item, index) => {
              return (
                <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                  <StyledCard className="h-full">
                    <Box component="figure" width="100%" m="0" p="8px">
                      <img
                        src={item?.home?.media?.[0]?.url || graphics}
                        alt="Home"
                        className="w-full aspect-[4/3] rounded-xl"
                      />
                    </Box>

                    <Box sx={{ px: 3, pb: 3 }}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Typography component="h3" variant="body1" fontWeight="600" className="self-center">
                          {item?.home?.name || ""} ({item?.guid || ""})
                        </Typography>
                        <Action
                          handleOnDelete={() => {
                            setDeleteId(item.id);
                            setOpenDeleteAlert(true);
                          }}
                          handleOnView={(e) => handleViewClick(e, item.home.id)}
                          permission={{
                            view: checkPermission(permissionList, "view home"),
                          }}
                        >
                          <>
                            {checkPermission(permissionList, "list selection") && (
                              <Action.ActionItem onClick={(e) => handleSelectionClick(e, userData.id, item.home.id)}>
                                <SummarizeIcon />
                                Selection
                              </Action.ActionItem>
                            )}

                            {checkPermission(permissionList, "list customization") && (
                              <Action.ActionItem onClick={(e) => handleCustomizeClick(e, userData.id, item.id)}>
                                <ColorLensIcon />
                                Customize
                              </Action.ActionItem>
                            )}

                            {checkPermission(permissionList, "list document") && (
                              <Action.ActionItem onClick={(e) => handleDocumentClick(e, userData.id, item.id)}>
                                <FileCopyOutlinedIcon />
                                Documents
                              </Action.ActionItem>
                            )}

                            {checkPermission(permissionList, "list progress") && (
                              <Action.ActionItem onClick={(e) => handleBuildingProgressClick(e, userData.id, item.id)}>
                                <DataUsageRoundedIcon />
                                Building Progress
                              </Action.ActionItem>
                            )}
                          </>
                        </Action>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}

      {userData?.userHomes?.length === 1 && <UserHomeView userHome={userData?.userHomes[0]} />}

      {userData?.userHomes?.length === 0 && (
        <Empty title="There is no home available!" subcontent={`Start by selecting home for ${userData?.name}.`} />
      )}

      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}
    </>
  );
};

export default MyHome;
